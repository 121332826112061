import type {SxProps, Theme} from "@mui/material";
import {Box} from "@mui/material";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {detailErrorResponse} from "../../helpers/control-helpers";
import type {FileOutputFormat, FileReaderResult} from "../../helpers/file-parser";
import {useToken} from "../../hooks/authentication/useToken";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {notifications} from "../../libs/notifications";
import {appStrings as strings} from "../../resources/strings/app";
import {api} from "../../services/imports.service";

interface Props {
  maxSize: number;
  allowedFileTypes: string[];
  allowedFileExtensions: string[];
  handleUpload: (file: FileReaderResult) => Promise<void>;
  outputFormat?: FileOutputFormat;
}
type Component = (props: Props) => JSX.Element;

const rootStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: "#FAFAFA",
  border: theme.spacing(0.1, "dashed", `${"#CCCCCC"}`),
  "&:hover": {
    cursor: "pointer",
  },
});

export const FileUpload: Component = ({maxSize, allowedFileTypes, allowedFileExtensions, handleUpload, outputFormat}) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState("");
  const onUpload = useCallback(handleUpload, [handleUpload]);

  const maxSizeInBytes = useMemo(() => maxSize * 1024, [maxSize]);

  const isTooLarge = useMemo(() => {
    if (!file) return false;
    return file.size > maxSizeInBytes;
  }, [file, maxSizeInBytes]);

  const isValidFileType = useMemo(() => {
    if (!file) return false;
    console.log("This file " + file.name + " has file type: " + file.type);

    const matchingExtensions = allowedFileExtensions.filter((x) => file.name.toLowerCase().endsWith(x.toLowerCase()));
    if (matchingExtensions.length === 0) {
      return false;
    }

    return allowedFileTypes.includes(file.type);
  }, [file, allowedFileTypes, allowedFileExtensions]);

  const jwt = useToken();
  const cancelToken = useCancelToken();

  useEffect(() => {
    if (!file || isTooLarge || !isValidFileType) return;

    const uploadFile = async () => {
      api
        .uploadAssetsFile(file, jwt, cancelToken)
        .then(() => window.location.reload())
        .catch((error) => {
          const errorInformation = detailErrorResponse(error);
          setError(errorInformation);
          notifications.error(errorInformation);
        });
    };

    uploadFile();
  }, [file, isTooLarge, isValidFileType, onUpload, outputFormat]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const {files} = e.target;
    if (!files) return;
    setFile(files[0]);
  };

  const onDrop: React.DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    const {files} = e.dataTransfer;
    if (!files) return;
    setFile(files[0]);
  };

  const onDragEnter: React.DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  const onDragOver: React.DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
  };

  const onClick = () => {
    if (!fileInput.current) return;
    fileInput.current.click();
  };

  const renderFileText = () => {
    if (!file) return strings.labels.uploadFile;
    if (isTooLarge) return strings.errors.fileTooLarge(file.name);
    if (!isValidFileType) return strings.errors.invalidFileType(file.name);
    if (error) return `Error Uploading File - ${error}`;
    return file.name;
  };

  return (
    <Box
      sx={[rootStyles]}
      display="flex"
      height={"100%"}
      width={"100%"}
      padding={4}
      justifyContent="center"
      onClick={onClick}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      data-testid="file-upload-root"
    >
      <input data-testid="file-upload-input" ref={fileInput} accept={allowedFileTypes.join()} type="file" hidden multiple={false} onChange={onChange} />
      {renderFileText()}
    </Box>
  );
};
