import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { SxProps, Theme } from "@mui/material";

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  menuItemClasses,
} from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMenuContext } from "../../context/MenuContext";
import { ShowIf, ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import type { MenuItemType } from "./listItems";
import { SubMenuItemContent } from "./SubMenuItemContent";

type MenuItemContentProps = {
  item: MenuItemType;
  index: number;
  parent?: string;
};

const selectedStyles: SxProps<Theme> = (theme) => ({
  fontFamily: "T-Safe-SemiBold",
  [`&.${menuItemClasses.selected}`]: {
    color: "#2643D6",
  },
});

const selectedTextStyles: SxProps<Theme> = {
  fontFamily: "T-Safe-SemiBold",
  fontWeight: "bolder",
};

export const MenuItemContent = ({
  item,
  index,
  parent,
}: MenuItemContentProps) => {
  const {
    permissions,
    open: menuOpen,
    selected,
    handleSelect,
  } = useMenuContext();

  const history = useNavigate();
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { key, type, label, Icon, itemPermission } = item;

  const isSelected = useMemo(() => {
    if (!parent) return selected.includes(key);
    const [selectedParent, selectedChild] = selected;
    return selectedParent === parent && selectedChild === key;
  }, [parent, selected, key]);

  useEffect(() => {
    if (!isSelected) setSubmenuOpen(false);
  }, [isSelected]);

  const { isStandardItem, link, childItems, hasChildItems } = useMemo(() => {
    const isStandardItem = type === "standard";

    const link = isStandardItem ? item.link : "";
    const childItems = isStandardItem ? [] : item.childItems;
    const hasChildItems = Boolean(childItems.length);
    return { isStandardItem, link, childItems, hasChildItems };
  }, [type, item]);

  const handleToggleSubmenu = () => {
    setSubmenuOpen((prev) => !prev);
  };

  const handleRedirect = (link: string) => {
    history(link);
  };

  const handleClick = () => {
    handleSelect(key, parent);

    if (isStandardItem) {
      handleRedirect(link);
    } else if (!parent) {
      handleToggleSubmenu();
    }
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (menuOpen || !hasChildItems) return;
    setAnchorEl(e.currentTarget);
    if (!submenuOpen) handleToggleSubmenu();
  };

  const handleClosePopover = () => setAnchorEl(null);

  const Content = (
    <>
      <ListItem
        component={ListItemButton}
        sx={[selectedStyles]}
        selected={isSelected}
        onClick={handleClick}
        //@ts-ignore
        onMouseEnter={handleMouseEnter}
      >
        <ListItemIcon sx={[isSelected && selectedStyles]}>
          {Icon && <Icon sx={[selectedStyles]} />}
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            sx: [isSelected && selectedTextStyles],
          }}
        />
        <ShowIf show={hasChildItems}>
          {submenuOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </ShowIf>
      </ListItem>
      <ShowIf show={hasChildItems && submenuOpen}>
        <SubMenuItemContent
          selectedStyles={selectedStyles}
          isSelected={isSelected}
          itemKey={key}
          childItems={childItems}
          anchorEl={anchorEl}
          handleClosePopover={handleClosePopover}
        />
      </ShowIf>
    </>
  );

  return (
    <Fragment key={`${label}-${index}`}>
      {itemPermission ? (
        <ShowIfAuthorised userPermissions={permissions} {...itemPermission}>
          {Content}
        </ShowIfAuthorised>
      ) : (
        <>{Content}</>
      )}
    </Fragment>
  );
};
