import {SxProps, Theme, Typography} from "@mui/material";
import {Button} from "./Button";
import {Modal} from "./Modal";

interface Props {
  strings: any;
  handleDelete: any;
  handleBack: any;
}
type Component = (props: Props) => JSX.Element;

const deleteActionsStyles: SxProps<Theme> = (theme) => ({
  color: "#CC3333",
  backgroundColor: "white",
  "&:hover": {
    color: "#CC3333",
    backgroundColor: "white",
    boxShadow: "none",
  },
  padding: 0,
  margin: 0,
  width: "100px",
  minWidth: "100px",
});

const cancelButtonStyles: SxProps<Theme> = (theme) => ({
  color: "#999999",
  backgroundColor: "white",
  "&:hover": {
    color: "#999999",
    backgroundColor: "white",
    boxShadow: "none",
  },
  padding: 0,
  margin: 0,
  width: "100px",
  minWidth: "100px",
});

const deleteButtonStyles: SxProps<Theme> = (theme) => ({
  color: "#CC3333",
  backgroundColor: "white",
});

const updateButtonStyles: SxProps<Theme> = (theme) => ({
  color: "white",
  backgroundColor: "#2643D6",
});

export const UpdateDeleteButtons: Component = ({strings, handleDelete, handleBack}) => {
  return (
    <>
      <Modal
        trigger={<Button label={strings.labels.delete} variant="outlined" sx={[deleteButtonStyles]} />}
        header={strings.header.deleteItem}
        actions={({onClose}) => [
          <Button label={strings.labels.cancel} onClick={onClose} sx={[cancelButtonStyles]} />,
          <Button label={strings.labels.delete} onClick={handleDelete} sx={[deleteActionsStyles]} />,
        ]}
        md={4}
      >
        <Typography style={{padding: 0}}>{strings.text.deleteConfirmation}</Typography>
      </Modal>
      <Button label={strings.labels.cancel} type="reset" variant="outlined" onClick={handleBack} sx={[cancelButtonStyles]} />
      <Button
        label="Update" //{strings.labels.save}
        variant="contained"
        type="submit"
        sx={[updateButtonStyles]}
      />
    </>
  );
};
