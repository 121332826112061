import {Box, SxProps, Theme} from "@mui/material";
import {useCallback, useState} from "react";
import {FileOutputFormat, FileReaderResult} from "../../helpers/file-parser";
import {Button} from "../general/Button";
import {FileUpload} from "../general/FileUpload";
import {Modal} from "../general/Modal";

interface Props {
  label: string;
  allowedFileTypes: string[];
  allowedFileExtensions: string[];
}

type Component = (props: Props) => JSX.Element;

export const UploadButton: Component = ({label}) => {
  const [fileData, setFileData] = useState("");
  const handleFileSelect = useCallback(async ({data}: FileReaderResult) => {
    if (typeof data === "string") setFileData(data);
  }, []);

  const rootStyles: SxProps<Theme> = (theme) => ({
    boxShadow: "none",
    fontWeight: "bolder",
    minWidth: theme.spacing(16),
    margin: theme.spacing(0, 1),
    borderRadius: "20px",
    backgroundColor: "#2643D6",
  });

  return (
    <>
      <Modal trigger={<Button label={label} sx={[rootStyles]} />} header={label} actions={() => []}>
        <Box sx={[{my: 2}]}>
          <FileUpload
            allowedFileTypes={["text/csv", "application/vnd.ms-excel"]} //A plain CSV file is being identified as ms-excel
            allowedFileExtensions={["csv"]}
            handleUpload={handleFileSelect}
            maxSize={102400}
            outputFormat={FileOutputFormat.Text}
          />
        </Box>
      </Modal>
    </>
  );
};
