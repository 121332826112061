import {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {CreateButton} from "../../components/common/CreateButton";
import {SearchBar} from "../../components/common/SearchBar";
import {useSessionStorage} from "../../hooks/general/useSessionStorage";
import paths from "../../navigation/paths";
import {clientStrings as strings} from "../../resources/strings/client";
import {api} from "../../services/clients.service";
import type {FilterChangeHandler} from "../../types";
import {Client, ClientFilters, ClientSort} from "../../types/documents/Client";
import {TableContainer} from "../general/TableContainer";

const ClientList = ({...routeProps}) => {
  const [filters, setFilters] = useSessionStorage<ClientFilters>({}, "clientFilters");

  const history = useNavigate();

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const clearFilters = () => setFilters({});

  const tableFilters = useMemo(() => ({...filters}), [filters]);
  return (
    <div>
      <TableContainer<Client>
        api={api}
        header={strings.header.clientList}
        headerContent={() => <CreateButton createPath={paths.client.create} label={"Create Client"} />}
        tableColumns={[
          {
            id: ClientSort.Name,
            Header: strings.labels.name,
            accessor: "Name",
            width: 250,
          },
          {
            id: ClientSort.Address1,
            Header: strings.labels.address1,
            accessor: "Address1",
          },
          {
            id: ClientSort.Address2,
            Header: strings.labels.address2,
            accessor: "Address2",
          },
          {
            id: ClientSort.City,
            Header: strings.labels.city,
            accessor: "City",
          },
          {
            id: ClientSort.Region,
            Header: strings.labels.region,
            accessor: "Region",
          },
          {
            id: ClientSort.Postcode,
            Header: strings.labels.postcode,
            accessor: "Postcode",
          },
          {
            id: ClientSort.Country,
            Header: strings.labels.country,
            accessor: "Country",
          },
        ]}
        manualPagination={true}
        children={
          <>
            <SearchBar filters={filters} handleFilterChange={handleFilterChange} clearFilters={clearFilters} tags={{}} />
          </>
        }
        filters={tableFilters as any}
        defaultSort={[
          {
            id: ClientSort.Name,
            desc: true,
          },
        ]}
        clickHandler={(client) => history(paths.client.modify(client.Id))}
        sortStorageKey={"clientSort"}
      />
      
    </div>
  );
};

export default ClientList;
