import {AxiosRequestConfig} from "axios";
import axios from "../libs/axios/axios-client";
import {
  CreateFunction,
  CreateResult,
  DeleteFunction,
  GetSelectedClientFunction,
  QueryFunction,
  ReadFunction,
  UpdateFunction,
  UpdateResult,
  UpdateSelectedClientFunction,
} from "../types/API";
import {Client, ClientFilters} from "../types/documents/Client";

type ClientPayload = Pick<Client, "Id" | "Name" | "Address1" | "Address2" | "City" | "Region" | "Postcode" | "Country">;

const getClientPayload = (payload: Client): ClientPayload => {
  const {Id, Name, Address1, Address2, City, Region, Postcode, Country} = payload;
  return {
    Id,
    Name,
    Address1,
    Address2,
    City,
    Region,
    Postcode,
    Country,
  };
};

const query: QueryFunction<Client> = async ({page = 0, size = 10, sort = [], query}, jwt?, cancelToken?) => {
  const path = "";
  const filters = query as ClientFilters;
  const {id, desc} = sort[0] ?? {};
  const config: AxiosRequestConfig = {
    params: {
      ...filters,
      page: page + 1,
      size: size,
      sort: id,
      order: desc ? "desc" : "asc",
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };

  const {data} = await axios.get<{items: Client[]; totalCount: number}>(path, config);
  return {
    items: data.items,
    totalCount: data.totalCount,
  };
};

const getClients: QueryFunction<Client> = async ({page = 0, size = 10, query}, jwt?, cancelToken?) => {
  const path = "";
  const filters = query as ClientFilters;
  const config: AxiosRequestConfig = {
    params: {
      ...filters,
      page: page + 1,
      size: size,
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };

  const {data} = await axios.get<{items: Client[]; totalCount: number}>(path, config);
  console.log(data);
  return {
    items: data.items,
    totalCount: data.totalCount,
  };
};

const create: CreateFunction<Client> = async ({payload}, jwt?, cancelToken?) => {
  const client = getClientPayload(payload);
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  client.Id = "00000000-0000-0000-0000-000000000000";
  const {data} = await axios.post<CreateResult>("", client, config);
  return data;
};

const update: UpdateFunction<Client> = async ({payload}, jwt?, cancelToken?) => {
  const clientData = getClientPayload(payload);
  const {Id} = payload;
  const path = `/${Id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  console.log(clientData);
  const {data} = await axios.put<UpdateResult>(path, clientData, config);
  return data;
};

const read: ReadFunction<Client> = async (id, _childId, jwt?, cancelToken?) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get<Client>(path, config);
  return {
    item: {
      ...data,
    },
  };
};

//Note - delete can't be used as a variable declaration name, so we use deleteClient
const deleteClient: DeleteFunction<Client> = async (id, jwt?, cancelToken?) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  await axios.delete(path, config);
};

const getCurrentClient: GetSelectedClientFunction<Client | null> = async (jwt, cancelToken) => {
  const path = `/current`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get<Client | string>(path, config);
  if (typeof data === "string") {
    if (data !== "") {
      throw new Error("Unexpected client result from API");
    } else {
      return null; //The API returns an empty string if no client was set
    }
  }
  return data as Client;
};

const setCurrentClient: UpdateSelectedClientFunction<Client> = async (clientId?, jwt?, cancelToken?) => {
  const path = clientId === "" || clientId === null || clientId === undefined ? `/current` : `/current?clientId=${clientId}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.put(path, {}, config);
  return data;
};

export const api = {
  query,
  update,
  create,
  read,
  deleteClient,
  getClients,
  getCurrentClient,
  setCurrentClient,
};
