import { useCallback, useEffect, useMemo, useState } from "react";
import type { Column, SortingRule } from "react-table";
import type { HeaderContent } from "../../components/general/Table";
import { Table } from "../../components/general/Table";
import { useClientContext } from "../../context/ClientContext";
import { logger } from "../../helpers/log-helpers";
import { useToken } from "../../hooks/authentication/useToken";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { useSessionStorage } from "../../hooks/general/useSessionStorage";
import type { QueryBody, QueryFunction } from "../../types/API";

export interface Props<D extends object> {
  api: {
    query: QueryFunction<D>;
  };
  header?: string;
  headerContent?: HeaderContent;
  tableColumns:
    | Column<D>[]
    | ((props: {
        setTableData: React.Dispatch<React.SetStateAction<D[]>>;
      }) => Column<D>[]);
  clickHandler?: (data: D) => void;
  manualPagination?: boolean;
  manualFiltering?: boolean;
  filters?: D;
  defaultSort?: SortingRule<D>[];
  children?: JSX.Element | Array<JSX.Element>;
  loading?: boolean;
  sortStorageKey?: string;
  inlineLoader?: boolean;
  topBorder?: boolean;
  pageSize?: number;
  hideHeaderColor?: boolean;
  headerSpacing?: number;
  scrollMaxBodyHeight?: number;
  scrollMaxRowCount?: number;
}

export const TableContainer = <D extends Record<string, unknown>>({
  api,
  header,
  tableColumns,
  clickHandler,
  manualPagination = false,
  filters: inputFilters,
  defaultSort = [],
  children,
  headerContent,
  loading: propsLoading,
  sortStorageKey = "sortObject",
  inlineLoader,
  topBorder = true,
  pageSize = 10,
  manualFiltering = true,
  hideHeaderColor = false,
  headerSpacing,
  scrollMaxBodyHeight,
  scrollMaxRowCount,
}: Props<D>) => {
  const [tableData, setTableData] = useState<D[]>(() => []);
  const [totalCount, setTotalCount] = useState(0);

  const [loading, setLoading] = useState(() => false);
  const [, setError] = useState("");
  const cancelToken = useCancelToken();
  const jwt = useToken();
  const { clientId, setClientId } = useClientContext();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(() => pageSize);
  const [sort, setSort] = useSessionStorage<SortingRule<D>[]>(
    defaultSort,
    sortStorageKey
  );
  const filters = useMemo(
    () => (manualFiltering ? inputFilters : undefined),
    [manualFiltering, inputFilters]
  );

  const columns = useMemo(
    () =>
      Array.isArray(tableColumns)
        ? tableColumns
        : tableColumns({ setTableData }),
    [tableColumns]
  );

  const sortObject = useMemo(() => sort, [sort]);

  const isLoading = [loading, propsLoading].some((l) => l);

  const searchCritera = useMemo<QueryBody<D>>(
    () => ({
      page,
      size,
      sort: sortObject,
    }),
    [page, size, sortObject]
  );

  useEffect(() => {
    const body: QueryBody<D> = manualPagination ? searchCritera : {};
    if (filters) {
      body.query = filters;
    }

    const query = async (token: string) => {
      setLoading(true);
      try {
        const { items = [], totalCount: count } = await api.query(
          body,
          token,
          cancelToken
        );
        setTableData(items);
        setTotalCount(count);
        setLoading(false);
      } catch (e) {
        if (cancelToken.reason) return;
        const error = logger.error(e);
        setError(error);
        setLoading(false);
      }
    };

    if (jwt) query(jwt); //Only call the query once the jwt has been retrieved
  }, [
    manualPagination,
    jwt,
    cancelToken,
    api,
    searchCritera,
    filters,
    clientId,
  ]);

  const onPageChange = useCallback((pageIndex: number) => {
    setPage(pageIndex);
  }, []);

  const onSizeChange = useCallback((pageSize: number) => {
    setSize(pageSize);
  }, []);

  const onSortChange = useCallback(
    (sortBy: SortingRule<D>[]) => {
      setSort(sortBy);
    },
    [setSort]
  );

  return (
    <div>
      <Table<D>
        // custom props
        header={header}
        loading={isLoading}
        clickHandler={clickHandler}
        onPageChange={onPageChange}
        onSizeChange={onSizeChange}
        onSortChange={onSortChange}
        sortObject={sortObject}
        children={children}
        headerContent={headerContent}
        inlineLoader={inlineLoader}
        topBorder={topBorder}
        filters={inputFilters}
        // react table props
        data={tableData}
        columns={columns}
        size={size}
        manualPagination={manualPagination}
        totalCount={totalCount}
        permissions={null}
        hideHeaderColor={hideHeaderColor}
        headerSpacing={headerSpacing}
        scrollMaxBodyHeight={scrollMaxBodyHeight}
        scrollMaxRowCount={scrollMaxRowCount}
      />
    </div>
  );
};
