import {AxiosRequestConfig, CancelToken} from "axios";
import axios from "../libs/axios/axios-import";
import {FileUpload, QueryFunction, ReadFunction} from "../types/API";
import {Import, ImportFilters} from "../types/documents/Import";

const query: QueryFunction<Import> = async ({page = 0, size = 10, sort = [], query}, jwt?, cancelToken?) => {
  const path = "";
  const filters = query as ImportFilters;
  const {id, desc} = sort[0] ?? {};
  const config: AxiosRequestConfig = {
    params: {
      ...filters,
      page: page + 1,
      size: size,
      sort: id,
      order: desc ? "desc" : "asc",
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };

  const {data} = await axios.get<{
    items: Import[];
    totalCount: number;
  }>(path, config);
  return {
    items: data.items,
    totalCount: data.totalCount,
  };
};

const read: ReadFunction<Import> = async (id, childId?, jwt?, cancelToken?: CancelToken) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get<{import: Import}>(path, config);
  return {
    item: {
      ...data.import,
    },
  };
};

const uploadAssetsFile: FileUpload = async (file, jwt?, cancelToken?) => {
  const path = `/assets?importtype=assetsandlocations`;
  const formData = new FormData();
  formData.append("file", file);
  const payload = formData;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.post<{id: string}>(path, payload, config);
  return data;
};

const approveImport = async (id: string, jwt?: string, cancelToken?: CancelToken) => {
  const path = `/${id}/approve`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.put(path, {}, config);
  return data;
};

const rejectImport = async (id: string, jwt?: string, cancelToken?: CancelToken) => {
  const path = `/${id}/reject`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.put(path, {}, config);
  return data;
};

export const api = {
  query,
  uploadAssetsFile,
  read,
  approveImport,
  rejectImport,
};
