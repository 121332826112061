import {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {CreateButton} from "../../components/common/CreateButton";
import {SearchBar} from "../../components/common/SearchBar";
import {formatDateTime} from "../../helpers/date-helpers";
import {useSessionStorage} from "../../hooks/general/useSessionStorage";
import paths from "../../navigation/paths";
import {assetStrings as strings} from "../../resources/strings/assets";
import {api} from "../../services/assets.service";
import type {FilterChangeHandler, RouteProps} from "../../types";
import {Asset, AssetFilters, AssetSort} from "../../types/documents/Asset";
import {TableContainer} from "../general/TableContainer";
import { Grid } from "@mui/material";
import { BaseControl } from "../../components/general/controls/BaseControl";
import { LocationSelectBox } from "../../components/general/controls/LocationSelectBox";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

type LocationDetails = {
  selectedLocation: {
    LocationId: string
  }
}

const AssetsList: Component = ({...routeProps}) => {
  const [filters, setFilters] = useSessionStorage<AssetFilters>({}, "clientFilters");

  const history = useNavigate();

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const clearFilters = () => setFilters({});

  const tableFilters = useMemo(() => ({...filters}), [filters]);

  const locationFilterChangeHandler = (event: any, locationDetails: LocationDetails) => {
    handleFilterChange("LocationId", locationDetails.selectedLocation.LocationId);
  }

  console.log("filters", filters)

  const renderAdditionalFilters = () => {
    return (
      <>
        <Grid item xs={4}>
          <BaseControl control={false}>
            <LocationSelectBox 
              config={{ name: "locationId", value: filters.LocationId }} 
              mode={"view"} 
              handleChange={locationFilterChangeHandler} 
            />
          </BaseControl>  
        </Grid>
      </>
    );
  };

  return (
    <div>
      <TableContainer<Asset>
        api={api}
        header={strings.header.assetList}
        headerContent={() => <CreateButton createPath={paths.assets.create} label={"Create Asset"} />}
        tableColumns={[
          {
            id: AssetSort.AssetType,
            Header: strings.labels.assetType,
            accessor: "AssetTypeName",
          },
          {
            id: AssetSort.AssetBarcode,
            Header: strings.labels.barcode,
            accessor: "AssetBarcode",
          },
          {
            id: AssetSort.Status,
            Header: strings.labels.status,
            accessor: "AssetStatus",
          },
          {
            id: AssetSort.FilterInstalled,
            Header: strings.labels.filterInstalled,
            accessor: "FilterTypeName",
          },
          {
            id: AssetSort.Location,
            Header: strings.labels.location,
            accessor: "FullLocationName",
          },
          {
            id: AssetSort.LastChanged,
            Header: strings.labels.lastChanged,
            accessor: (x) => {
              return <p>{x.AssetFilterInstalled ? formatDateTime(x.AssetFilterInstalled) : "-"}</p>;
            },
          },
        ]}
        manualPagination={true}
        children={
          <>
            <SearchBar filters={filters} handleFilterChange={handleFilterChange} clearFilters={clearFilters} additionalFilterComponents={renderAdditionalFilters()} tags={{}} />
          </>
        }
        filters={tableFilters as any}
        defaultSort={[
          {
            id: AssetSort.AssetType,
            desc: true,
          },
        ]}
        sortStorageKey={"assetSort"}
        clickHandler={(asset) => history(paths.assets.modify(asset.AssetId))}
      />
    </div>
  );
};

export default AssetsList;
